import React, { useContext } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { passwordSchema } from "../../schema/passwordSchema";
import { useFormik } from "formik";
import { Container } from "@mui/material";
import loksewaLogo from "../../assets/images/logo3.png";
import logo1 from "../../assets/images/logo2.png";
import Button from "@mui/material/Button";
import { baseURL } from "../../config/baseURL";
import axios from "axios";
import { NumberContext } from "../../pages/Login";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

const UpdatePassword = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email:"",
        password: "",
        confirmPassword: "",
      },
      validationSchema: passwordSchema,
    });
  const value = useContext(NumberContext);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("confirm_password", values.confirmPassword);
    formData.append("password_update_signature", value.passwordSignature);
    // console.log(errors.password === undefined &&
    //   errors.confirmPassword === undefined)
    handleSubmit();
    if (
      values.password &&
      values.confirmPassword !== "" &&
      errors.password === undefined &&
      errors.confirmPassword === undefined &&
      errors.email === undefined
    ) {
      value.setLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/daily-quiz/update-password`,
          formData
        );
        if (response.status === 200) {
          value.setOpen(true);
          value.setOptions({
            message: response.data.message,
            severity: "success",
          });
          localStorage.setItem("access_token", response.data.data.access_token);
          localStorage.setItem("phone", response.data.data.user.phone);
          sessionStorage.setItem("tokenApproved", true);
          navigate("/question");
          value.setLoading(false);
          setIsSubmitting(true);
          value.setOpen(true);
          value.setOptions({
            message: response.data.message,
            severity: "success",
          });
        }
        setIsSubmitting(false);
      } catch (err) {
        value.setLoading(false);
        console.log(err.response);
        if (err.response.data.success === false) {
          value.setLoading(false);
          value.setOpen(true);
          value.setOptions({
            message: err.response.data.message,
            severity: "error",
          });
        }
      }
    }
  };

  return (
    <form>
      <div>
        <Container maxWidth="sm" className="login-inner-container">
          <img src={logo1} alt="logo" className="logo-img image" />
          <p className="text">
            OTP verify successfully, Please set up your password.
          </p>
          <div className="password-form">
            <div>
              <TextField
                id="outlined-start-adornment"
                className={
                  errors.email && touched.email
                    ? "input-error form-field"
                    : "form-field"
                }                sx={{ m: 1 }}
                placeholder="Email(Optional)"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email && (
                <div className="error-message" style={{ color: "red" }}>
                  {errors.email}
                </div>
              )}
            </div>
            <div>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                className={
                  errors.password && touched.password
                    ? "input-error form-field"
                    : "form-field"
                }
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="New Password"
                />
              </FormControl>
              {errors.password && touched.password && (
                <div className="error-message" style={{ color: "red" }}>
                  {errors.password}
                </div>
              )}
            </div>
            <div>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? "input-error form-field"
                    : "form-field"
                }
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Confirm Password"
                />
              </FormControl>
              {errors.confirmPassword && touched.confirmPassword && (
                <div className="error-message" style={{ color: "red" }}>
                  {errors.confirmPassword}
                </div>
              )}
            </div>
          </div>
        </Container>
        <Container maxWidth="sm" className="p-0 w-100  footer1">
          <Button
            variant="contained"
            className="btn-primary button"
            type="submit"
            disabled={isSubmitting}
            onClick={handleUpdatePassword}
          >
            Save
          </Button>
        </Container>
      </div>
    </form>
  );
};

export default UpdatePassword;
