import React, { createContext, useState } from "react";
import logo from "../assets/images/logo1.png";
import { Alert, Container } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import PhoneTextField from "../components/Login/PhoneTextField";
import OtpCode from "../components/Login/OtpCode";
import UpdatePassword from "../components/Login/UpdatePassword";
import { useFormik } from "formik";
import { phoneSchema } from "../schema/phoneSchema";
import LoginField from "../components/Login/LoginField";
import Loader from "../components/Loader/Loader";

export const NumberContext = createContext();
const Login = () => {
    //snackbar
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState({
        message: "",
        severity: ""
    });
    const [sendOtp, setSendOtp] = useState(false);
    const [addPassword, setAddPassword] = useState(false);
    const [userVerified, setUserVerified] = useState(false)
    const [passwordSignature, setPasswordSignature] = useState(null)
    const [loading, setLoading] = useState(false)
    //formik
    const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: {
                //for phone number
                phone: "",
                password: "",
            },
            validationSchema: phoneSchema,
        });
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            {
                loading ?
                    <Loader /> :
                    <div>
                        <Container maxWidth="sm" className="outer-container">
                            <div className="login-header">
                                <div className="background-image-login">
                                    <img src={logo} alt="logo" className="image" />
                                </div>
                            </div>
                        </Container>
                        <div className="circle"></div>
                        <div>

                            <NumberContext.Provider value={{ values, touched, errors, handleBlur, handleChange, handleSubmit, setOpen, setAddPassword, setOptions, setSendOtp, passwordSignature, setPasswordSignature, setUserVerified, setLoading }}>
                                {
                                    userVerified ?
                                        <LoginField /> :
                                        <div>
                                            {addPassword ?
                                                <UpdatePassword /> :
                                                <div>
                                                    {
                                                        sendOtp ?
                                                            <OtpCode /> :
                                                            <PhoneTextField />
                                                    }
                                                </div>
                                            }
                                        </div>
                                }
                            </NumberContext.Provider>
                        </div>
                    </div>
            }
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={3000}
            >
                {options.severity ? (
                    <Alert onClose={handleClose} severity={options.severity} sx={{ width: '100%' }}>
                        {options.message}
                    </Alert>
                ) : null}
            </Snackbar>
        </div>
    );
};

export default Login;
