import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const CircleLoader = () => {
    return (
        <div>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#FFA000', '#FFA000', '#FFA000', '#FFA000', '#FFA000']}
            />
        </div>
    )
}

export default CircleLoader