import React from "react";

import { Container, Button } from "@mui/material";

import facebook from "../assets/images/facebook.png";
import instagram from "../assets/images/insta.png";
import tiktok from "../assets/images/tiktok.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HomeIcon from "@mui/icons-material/Home";

const ResultSheet = () => {
  const answers = JSON.parse(sessionStorage.getItem("answer"));
  // const result = 4;
  // const totalResults = Math.ceil(answers?.length / result);

  // const [currentResult, setCurrentResult] = useState(0);

  const displayResults = () => {
    // const startIndex = currentResult * result;
    // const endIndex = startIndex + result;
    return answers.map((item, index) => (
      <div key={index}>
        {/* Display each question and correct answer */}
        <div className="all-question-container">{item.question}</div>
        <div className="answer-container">
          <ol type="A">
            <p className="my-0">Your Answer: </p>
            <li
              className={
                item.answer === item.correct_answer
                  ? "correct-answer"
                  : "wrong-answer"
              }
            >
              <div className="answer-alignment">
                {item.answer}
                {item.answer === item.correct_answer ? (
                  <CheckIcon className="icon" />
                ) : (
                  <CloseIcon className="icon" />
                )}
              </div>
            </li>
            <p className="my-0">Correct Answer:</p>
            <li className="selected-answer result-answer">
              {item.correct_answer}
            </li>
          </ol>
        </div>
      </div>
    ));
  };

  // const handleNext = () => {
  //   setCurrentResult((prevResult) => prevResult + 1);
  // };

  // const handlePrev = () => {
  //   setCurrentResult((prevResult) => prevResult - 1);
  // };

  // const progressbar = ((currentResult + 1) / totalResults) * 100;
  return (
    <div>
      <Container maxWidth="sm" className="outer-container">
        <div className="welcome-header">
          <div className="result-background-image">
            <div className="header-alignment">
              <Link to="/">
                <div className="icon-circle">
                  <HomeIcon />
                </div>
              </Link>
              <h1>Check your answer</h1>
            </div>
          </div>
        </div>
      </Container>
      <div className="result-circle"></div>
      <Container maxWidth="sm">
        <div className="result-sheet">{displayResults()}</div>

        {/* {currentResult !== totalResults - 1 && (
                    <Button
                        variant="contained"
                        className="btn-primary button"
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                )} */}
        <Link to="/question">
          <Button
            variant="contained"
            className="btn-secondary button"
            startIcon={<RestartAltIcon />}
          >
            Restart Quiz
          </Button>{" "}
        </Link>
        <div className="result-footer">
          <p>Like our social media pages</p>
          <div className="social-icon">
            <a
              href="https://www.facebook.com/LoksewaproNepal"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" width={30} />
            </a>
            <a
              href="https://www.instagram.com/loksewapro/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" width={30} />
            </a>
            <a
              href="https://www.facebook.com/LoksewaproNepal"
              target="_blank"
              rel="noreferrer"
            >
              <img src={tiktok} alt="tiktok" width={30} />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResultSheet;
