import * as yup from "yup";

//validation regex
const testPhone = /^(9)[6-8][0-9]{8}$/;

export const phoneSchema = yup.object().shape({
    phone: yup.string().matches(testPhone, {
        message: "Phone number is incorrect"
    }).required("This field is required"),
    password: yup.string()
    // .matches(/^\S*$/, "Password must not contain any space")
    // .matches(/[a-z]+/, "Password must have one lowercase character")
    // .matches(/[A-Z]+/, "Password must have one uppercase character")
    // .matches(/[@$!%*#?&]+/, "Password must have one special character")
    // .matches(/\d+/, "Password must have one number")
    // .min(8, "Password must be 8 characters or more")
    // .max(16, "Password must be 16 characters or less")
    .required("This field is required"),
})  