import React from "react";
import { Button, Container, Alert } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import thankyou from "../assets/images/thankyou.png";
import facebook from "../assets/images/facebook.png";
import instagram from "../assets/images/insta.png";
import tiktok from "../assets/images/tiktok.png";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { baseURL } from "../config/baseURL";
import axios from "axios";

const ThankYouPage = () => {
  const [value, setValue] = React.useState(0);
  const [feedback, setFeedback] = React.useState("");
  const [message,setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const navigate = useNavigate();

  const handleReviewQuiz = async () => {
    setIsDisabled(true);
    if (value > 0) {
      const result_id = sessionStorage.getItem("result_id");
      const token = localStorage.getItem("access_token");
      const formData = new FormData();
      formData.append("rating", value);
      formData.append("daily_quiz_result_id", result_id);
      formData.append("feedback", feedback);

      try {
        const response = await axios.post(
          `${baseURL}/daily-quiz-set/submit-review`,
          formData,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          navigate("/score");
          setOpen(true);
          setMessage(response.data.message);
          setIsDisabled(false);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setOpen(true);
      setSeverity("error")
      setIsDisabled(false);
      setMessage("Please give us a rating to see your score and correct answers");
    }
  };

  const handleClose = () => {
    setOpen(false);
};


  return (
    <div>
      <Container maxWidth="sm" className="outer-container">
        <div className="welcome-header">
          <div className="result-background-image">
            <h2 className="header-topic">Thank you for playing the quiz</h2>
          </div>
        </div>
      </Container>
      <div className="result-circle"></div>
      <Container maxWidth="sm">
        <div className="thankyou-img-container">
          <img src={thankyou} alt="thank-youImg" className="img" />
        </div>
        <div className="thank-you-content mb-1">
          {/* <h2 className="my-0 mb-1">Thank you for playing the quiz</h2> */}
          <p className="my-0 disclaimer mb-1">
            *Rate our quiz question before seeing your score and answers
          </p>
          {/* <p className="my-0 mb-2">
            If you want to prepare yourself for loksewa then stay tuned.
            Something is coming soon. */}
          {/* If you want to prepare yourself for loksewa then install our loksewa
            app from playstore or app store */}
          {/* </p> */}
          <div className="rating-review">
            {/* <Typography component="legend">Rating</Typography> */}
            <div className="rating">
              <div className="rating-name">
                <p className="my-0">Rate us</p>
                {value > 0 ? (
                  <p className="ratings-count my-0">
                    {value}
                    <span>/5</span>
                  </p>
                ) : (
                  <p className="my-0"></p>
                )}
              </div>
              <div
                className="rating-numbers"
                style={{ margin: value > 0 ? "0 0" : "0.5rem 0" }}
              >
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  size="large"
                />
                <div>
                  <p className="my-0">Out of 5</p>
                </div>
              </div>
            </div>
            {/* {error != "" && <p className="rating-error">{error}</p>} */}
            <div className="review">
              <div className="review-label">
              <p className="my-0">Review</p>
              </div>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                sx={{marginTop:"0.75rem"}}
                placeholder="Give your feedback"
                size="small"
                value={feedback}
                onChange={(e) => 
                  setFeedback(e.target.value)
                }
              />
            </div>
          </div>
          {/* <Paper
            elevation={0}
            sx={{
              width: "50%",
              margin: "auto",
              marginTop: "1rem",
              cursor: "pointer",
            }}
          >
            <img src={google} alt="google" className="img" />
          </Paper> */}
        </div>
        {/* <Link to="/score"> */}
        <Button
          variant="contained"
          className="btn-secondary button"
          disabled={isDisabled}
          onClick={handleReviewQuiz}
        >
          Check your score
        </Button>{" "}
        {/* </Li/nk> */}
        <div className="result-footer">
          <p>Like our social media pages</p>
          <div className="social-icon">
            <a
              href="https://www.facebook.com/LoksewaproNepal"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" width={30} />
            </a>
            <a
              href="https://www.facebook.com/LoksewaproNepal"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" width={30} />
            </a>
            <a
              href="https://www.facebook.com/LoksewaproNepal"
              target="_blank"
              rel="noreferrer"
            >
              <img src={tiktok} alt="tiktok" width={30} />
            </a>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={handleClose}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default ThankYouPage;
