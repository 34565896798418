import './App.css';
import "./assets/scss/style.scss"
import WelcomePage from './pages/WelcomePage';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import QuizQuestion from './pages/QuizQuestion';
import About from './pages/About';
import ResultSheet from './pages/ResultSheet';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import ScorePage from './pages/ScorePage';
import ThankYouPage from './pages/ThankYouPage';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<WelcomePage />} />
          <Route path='/question' element={<QuizQuestion/>} />
          <Route path="/login" element={<Login />} />
          {/* <Route path='/about' element={<About />} /> */}
          <Route path='/result' element={<ResultSheet />} />
          <Route path='/score' element={<ScorePage />} />
          <Route path='/thankyou' element={<ThankYouPage />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
