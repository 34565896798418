import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../config/baseURL";
import loksewaLogo from "../../assets/images/logo3.png";
import logo1 from "../../assets/images/logo2.png";
import { NumberContext } from "../../pages/Login";
import CircleLoader from "../Loader/CircleLoader";

const PhoneTextField = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const value = useContext(NumberContext);

  //send mobile number
  const handleSend = async (e) => {
    e.preventDefault();
    value.handleSubmit();
    if (value.values.phone !== "" && value.errors.phone === undefined) {
      value.setLoading(true);
      try {
        const formData = new FormData();
        try {
          formData.append("phone", value.values.phone);
          const res = await axios.post(
            `${baseURL}/daily-quiz-access`,
            formData
          );
          if (res.status === 200) {
            setIsSubmitting(true);
            value.setUserVerified(true);
            value.setLoading(false);
            if (
              res.data.phone_exists === false ||
              res.data.phone_exists === true
            ) {
              value.setSendOtp(true);
              value.setUserVerified(false);
              value.setOpen(true);
              setIsSubmitting(true);
              value.setOptions({
                message: res.data.message,
                severity: "success",
              });
              value.setLoading(false);
            }
            if (res.data.password_verified === false) {
              value.setSendOtp(true);
              value.setUserVerified(false);
              value.setOpen(true);
              setIsSubmitting(true);
              value.setOptions({
                message: res.data.message,
                severity: "warning",
              });
              value.setLoading(false);
            } else if (res.data.status === 422) {
              value.setOpen(true);
              setIsSubmitting(true);
              value.setOptions({
                message: res.data.message,
                severity: "warning",
              });
              value.setLoading(false);
            }
          }
          setIsSubmitting(true);
        } catch (err) {
          value.setLoading(false);
          value.setOpen(true);
          value.setOptions({
            message: err.response.data.message,
            severity: "error",
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    }
  };

  return (
    <form className="login-form" onSubmit={handleSend}>
      <Container maxWidth="sm" className="login-inner-container">
        <img src={logo1} alt="logo" className="logo-img image" />
        <p className="text">
          To continue and finish your quiz, Please login/sign up with your phone number.
        </p>
        <div>
          <TextField
            id="outlined-start-adornment"
            className={
              value.errors.phone && value.touched.phone
                ? "input-error form-field"
                : "form-field"
            }
            sx={{ m: 1 }}
            placeholder="Phone Number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">977</InputAdornment>
              ),
            }}
            name="phone"
            value={value.values.phone}
            onChange={value.handleChange}
            onBlur={value.handleBlur}
          />
          {value.errors.phone && value.touched.phone && (
            <div className="error-message" style={{ color: "red" }}>
              {value.errors.phone}
            </div>
          )}
        </div>
      
      </Container>
      <Container maxWidth="sm" className="p-0 w-100  footer ">
        <Button
          variant="contained"
          className="btn-primary button"
          type="submit"
          disabled={isSubmitting}
        >
          Send {isSubmitting && <CircleLoader />}
        </Button>
      </Container>
    </form>
  );
};

export default PhoneTextField;
