import React from "react";
import ErrorImg from "../assets/images/error.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="error">
      <div className="error-image">
      <img src={ErrorImg} alt="error" className="error-gif" />
      </div>
        <div className="error-page-message">
          <p className="message">Look like you're lost</p>
          <p>the page you are looking for is not available </p>
          <p>OR</p>
          <p>Check your internet connection</p>
          <Link to="/">
            <Button variant="contained" className='btn-secondary'>
              Go back to home page
            </Button>
          </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
