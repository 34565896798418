import React, { useContext, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { passwordSchema } from "../../schema/passwordSchema";
import { useFormik } from "formik";
import { Container } from "@mui/material";
import loksewaLogo from "../../assets/images/logo3.png";
import logo1 from "../../assets/images/logo2.png";
import { NumberContext } from "../../pages/Login";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { baseURL } from "../../config/baseURL";
import { Link, useNavigate } from "react-router-dom";

const LoginField = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const value = useContext(NumberContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate("");
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: passwordSchema,
    });

  const handleLogin = async (e) => {
    e.preventDefault();
    handleSubmit();
    if (
      value.values.phone &&
      values.password !== "" &&
      errors.password === undefined
    ) {
      value.setLoading(true);
      const formData = new FormData();
      formData.append("phone", value.values.phone);
      formData.append("password", values.password);
      try {
        const response = await axios.post(`${baseURL}/login`, formData);
        if (response?.status === 200) {
          localStorage.setItem("access_token", response.data.data.access_token);
          localStorage.setItem("phone", response.data.data.user.phone);
          sessionStorage.setItem('tokenApproved', true);
          navigate("/question");
          value.setOpen(true);
          value.setOptions({
            message: response.data.message,
            severity: "success",
          });
          setIsSubmitting(true);
          value.setLoading(false);
        }
        setIsSubmitting(false);
      } catch (err) {
        console.log(err.response);
        if (err.response?.status === 401) {
          value.setOpen(true);
          value.setOptions({
            message: err.response.data.message,
            severity: "error",
          });
          setIsSubmitting(true);
          value.setLoading(false);
        }
        if (err.response?.status === 422) {
          setIsSubmitting(true);
          value.setLoading(false);
        }
        value.setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  const handlePasswordChange = async () => {
    const formData = new FormData();
    formData.append("phone", value.values.phone);
    value.setLoading(true);
    try {
    const response = await axios.post(`${baseURL}/password/forgot`, formData);
    console.log(response);
      if (response.status === 200) {
        localStorage.setItem("phone", value.values.phone);
        value.setOpen(true);
        value.setOptions({
          message: response.data.message,
          severity: "success",
        });
        navigate("/forgotpassword");
        value.setLoading(false);
      }
      else{
        value.setLoading(false)
      }
    } catch (err) {
        value.setOpen(true);
        value.setOptions({
          message: err.response.data.message,
          severity: "error",
        });
    setIsSubmitting(false);
      value.setLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={handleLogin}>
      <Container maxWidth="sm" className="login-inner-container">
        <img src={logo1} alt="logo" className="logo-img image" />
        <div className="login-form">
          <p className="text">Please fill your password to proceed </p>
          <div>
            <TextField
              id="outlined-start-adornment"
              className={
                value.errors.phone && value.touched.phone
                  ? "input-error form-field"
                  : "form-field"
              }
              sx={{ m: 1 }}
              placeholder="Phone Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">977</InputAdornment>
                ),
              }}
              name="phone"
              value={value.values.phone}
              onChange={value.handleChange}
              onBlur={value.handleBlur}
              disabled
            />
            {value.errors.phone && value.touched.phone && (
              <div className="error-message" style={{ color: "red" }}>
                {value.errors.phone}
              </div>
            )}
          </div>
          <div>
            <FormControl
              sx={{ m: 1 }}
              variant="outlined"
              className={
                errors.password && touched.password
                  ? "input-error form-field"
                  : "form-field"
              }
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Password"
              />
            </FormControl>
            {errors.password && touched.password && (
              <div className="error-message" style={{ color: "red" }}>
                {errors.password}
              </div>
            )}
          </div>
          <p className="my-0 forgot-password" onClick={handlePasswordChange}>
            Forgot Password?{" "}
          </p>
        </div>
      </Container>
      <Container maxWidth="sm" className="p-0 w-100  footer1">
        <Button
          variant="contained"
          className="btn-primary button"
          type="submit"
          disabled={isSubmitting}
        >
          Login
        </Button>
      </Container>
    </form>
  );
};

export default LoginField;
