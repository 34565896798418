import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo1.png";
import logo1 from "../assets/images/logo2.png";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../config/baseURL";

const WelcomePage = () => {
  const [activeQuizSet, setActiveQuizSet] = useState("");
  const [questionLength, setQuestionLength] = useState(0);
  const [title, setTitle] = useState(" ");
  const navigate = useNavigate("");

  useEffect(() => {
    //get active quiz set from api
    const getActiveDailyQuizSet = async () => {
      try {
        const res = await axios.get(`${baseURL}/daily-quiz-set`);
        if (res.data.data.questions.length === 0) {
          navigate("*");
        }
        setActiveQuizSet(res.data.data.activeDailyQuizSet);
        setTitle(res.data.data.title);
        let totalQuestion = res.data.data.questions.length;
        setQuestionLength(totalQuestion);
      } catch (error) {
        if (error.response?.status === 429) {
          navigate("/error-page");
        }
      }
    };
    const checkToken = async () => {
      const token = localStorage.getItem("access_token");
      const formData = new FormData();
      try {
        const res = await axios.post(`${baseURL}/user`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 200) {
          sessionStorage.setItem("tokenApproved", true);
        } else {
          sessionStorage.setItem("tokenApproved", false);
        }
      } catch (error) {
        sessionStorage.setItem("tokenApproved", false);
        if (error.response?.status === 429) {
          navigate("/error-page");
        }
        else if(error.response?.status === 401){
          localStorage.removeItem("phone");
          localStorage.removeItem("access_token");
          localStorage.removeItem("continued");
        }
      }
    };
    getActiveDailyQuizSet();
    checkToken();
    sessionStorage.removeItem("answer");
    sessionStorage.removeItem("activeStep");
    sessionStorage.removeItem("questionResponse");
    sessionStorage.removeItem("correct_answer");
    sessionStorage.removeItem("total_questions");
    sessionStorage.removeItem("score");
  }, []);
  return (
    <div>
      <Container maxWidth="sm" className="outer-container">
        <div className="welcome-header">
          <div className="background-image">
            <h1 className="header">Welcome to</h1>
            <img src={logo} alt="logo" className="image" />
          </div>
        </div>
      </Container>
      <div className="circle"></div>
      <Container maxWidth="sm" className="inner-container ">
        <img src={logo1} alt="logo" className="logo-img image" />
        <div className="card-btn">
          {/* <span> Quiz No: {activeQuizSet}</span> */}
          <p className="my-0">{title}</p>
        </div>
        <div className="text-content mx-auto">
          <h2 className="mb-0">{questionLength} Questions</h2>
        </div>
        <div className="footer">
          {/* <div className='footer-image'>
                    <Link to="/question">
                        <Button variant="contained" className='btn-primary start-btn'>Let's Start</Button>
                    </Link>
                </div> */}
        </div>
        {/* <img src={loksewaLogo} alt='image'/> */}
      </Container>
      <Container maxWidth="sm" className="p-0 w-100  welcome-footer">
        <Link to="/question">
          <Button variant="contained" className="btn-primary button">
            Let's Start
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export default WelcomePage;
