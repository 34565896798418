import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Container } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Loader from "../components/Loader/Loader";

import axios from "axios";
import { baseURL, imageURL } from "../config/baseURL";

import { motion, AnimatePresence } from "framer-motion";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFA000" : "#308fe8",
  },
}));
var tokenApproved;

const QuizQuestion = () => {
  const navigate = useNavigate();
  const [[activeStep, direction], setActiveStep] = useState([0, 0]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answerSelected, setAnswerSelected] = useState(false);
  const [data, setData] = useState([]);
  const [dailyQuizID, setDailyQuizId] = useState("");
  const [loading, setLoading] = useState(false);
  const [interactionType, setInteractionType] = useState(null);
  //snackbar
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    message: "",
    severity: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const storedActiveStep = isNaN(
    parseInt(sessionStorage.getItem("activeStep"), 10)
  )
    ? 0
    : parseInt(sessionStorage.getItem("activeStep"), 10);
  const currentStep = storedActiveStep;
  const currentQuestion =
    currentStep < 5 ? data[currentStep] : data[currentStep];
  //progress value
  const progressValue = ((currentStep + 1) / data.length) * 100;

  useEffect(() => {
    //get question from api
    const getQuestion = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${baseURL}/daily-quiz-set`);
        if (res.data.data.questions.length === 0) {
          navigate("*");
        }
        setData(res.data.data.questions);
        setDailyQuizId(res.data.data.activeDailyQuizSet);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response?.status === 429 || error?.response === undefined) {
          navigate("/error-page");
        }
      }
    };
    getQuestion();
  }, []);

  useEffect(() => {
    const getTokenCheck = async () => {
      tokenApproved = await JSON.parse(sessionStorage.getItem("tokenApproved"));

      if (localStorage.getItem("access_token") === null || !tokenApproved) {
        if (currentStep >= 5) {
          navigate("/login");
        }
        // localStorage.setItem("continued", false);
      }
    };
    getTokenCheck();

    const storedQuestionResponses = JSON.parse(
      sessionStorage.getItem("questionResponse")
    );
    if (storedQuestionResponses) {
      const selectedAnswer = storedQuestionResponses[currentQuestion?.id];
      if (selectedAnswer) {
        setSelectedAnswer(selectedAnswer);
      }
    }
  }, [currentQuestion?.id]);

  //logic to show questions according to the step
  //function for changing questions
  const handleSwipe = async (newDirection) => {
    const nextStep = currentStep + newDirection;
    tokenApproved = await JSON.parse(sessionStorage.getItem("tokenApproved"));

    setInteractionType("swipe");
    if (currentStep < data.length) {
      setActiveStep([nextStep, newDirection]);
      setSelectedAnswer(null);
      setAnswerSelected(false);
      sessionStorage.setItem("activeStep", String(currentStep + newDirection));
      setLoading(false);

      // Check conditions and navigate based on your requirements
      if (
        nextStep === 5 &&
        localStorage.getItem("access_token") === null &&
        !tokenApproved
      ) {
        console.log("hello");
        setLoading(true);
        navigate("/login");
      }
      if (
        nextStep === 10 &&
        localStorage.getItem("access_token") === null &&
        !tokenApproved
      ) {
        setLoading(true);
        navigate("/login");
      }
      // if (
      //   nextStep === 10 &&
      //   // localStorage.getItem("access_token") !== null &&
      //   JSON.parse(localStorage.getItem("continued")) === false 
      // ) {
      //   setLoading(true);
      //   navigate("/about");
      // }
    } else {
      // Handle end of the quiz
    }
  };

  // const handleNext = () => {
  //   setInteractionType("button");
  //   if (currentStep < data.length - 1) {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     setSelectedAnswer(null);
  //     setAnswerSelected(false);
  //     sessionStorage.setItem("activeStep", String(currentStep + 1));
  //     setLoading(false);
  //     // Check conditions and navigate
  //     if (currentStep + 1 === 5 &&
  //       localStorage.getItem("access_token") === null) {
  //       setLoading(true);
  //       console.log("ho")
  //       setTimeout(() => {
  //         navigate("/login");
  //       }, 1000);
  //     }
  //     if (
  //       currentStep + 1 === 10 &&
  //       localStorage.getItem("access_token") === null
  //     ) {
  //       setTimeout(() => {
  //         setLoading(true);
  //         navigate("/login");
  //       }, 1000);
  //     }
  //     if (
  //       currentStep + 1 === 10 &&
  //       localStorage.getItem("access_token") !== null  &&
  //       localStorage.getItem("continued") ===  null
  //     ) {
  //       setTimeout(() => {
  //         setLoading(true);
  //         navigate("/about");
  //       }, 1000);
  //     }
  //   } else {
  //     // Handle end of the quiz
  //   }
  // };

  //to determine the last question
  const isLastQuestion = currentStep + 1 === data.length;

  //selected answer
  const handleOptionClick = (questionId, option) => {
    // if (!answerSelected) {
    setSelectedAnswer(option.id);
    setAnswerSelected(true);

    const questionResponse =
      JSON.parse(sessionStorage.getItem("questionResponse")) || {};
    questionResponse[questionId] = option.id;
    sessionStorage.setItem(
      "questionResponse",
      JSON.stringify(questionResponse)
    );
    // }
  };

  //after finishing the value
  const handleFinish = async () => {
    setIsDisabled(true);
    const questionResponse = JSON.parse(
      sessionStorage.getItem("questionResponse")
    );
    const phone = localStorage.getItem("phone");
    const token = localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append("daily_quiz_set_id", dailyQuizID);
    formData.append("phone", phone);
    for (const [questionId, answerId] of Object.entries(questionResponse)) {
      formData.append(`questions[${questionId}]`, answerId);
    }
    try {
      const response = await axios.post(
        `${baseURL}/daily-quiz-set/submit-answers`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        sessionStorage.removeItem("questionResponse");
        sessionStorage.removeItem("activeStep");
        sessionStorage.setItem(
          "answer",
          JSON.stringify(response.data.data.quizResult.answers)
        );
        sessionStorage.setItem(
          "correct_answer",
          JSON.stringify(response.data.data.quizResult.total_correct_answers)
        );
        sessionStorage.setItem(
          "score",
          JSON.stringify(response.data.data.quizResult.score)
        );
        sessionStorage.setItem(
          "total_questions",
          JSON.stringify(response.data.data.quizResult.total_questions)
        );
        sessionStorage.setItem(
          "result_id",
          JSON.stringify(response.data.data.quizResult.result_id)
        );
        setIsDisabled(false);
        navigate("/thankyou");
      }
    } catch (err) {
      if (err.response.status === 500) {
        setOpen(true);
        setIsDisabled(false);
        setOptions({ message: "Internal Server Error", severity: "error" });
      } else if (err.response.status === 401) {
        setOpen(true);
        setIsDisabled(false);
        setOptions({
          message: "Please login to submit your answers",
          severity: "error",
        });
        navigate("/login");
      }
    }
  };

  //snackbar close
  const handleClose = () => {
    setOpen(false);
  };

  //framer motion for animation
  const variants = {
    swipeEnter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    swipeCenter: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    swipeExit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    buttonEnter: () => {
      return {
        x: 10,
        opacity: 0,
      };
    },
    buttonCenter: () => {
      return {
        x: 0,
        opacity: 1,
      };
    },
    buttonExit: () => {
      return {
        x: -10,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const htmlString = currentQuestion?.question;
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <AnimatePresence mode="wait" initial={false} custom={direction}>
          <motion.div
            key={activeStep}
            // initial={{ x: 10, opacity: 0 }}
            // animate={{ x: 0, opacity: 1 }}
            // exit={{ x: -10, opacity: 0 }}
            // transition={{ duration: 0.4 }}
            custom={direction}
            variants={variants}
            initial={
              interactionType === "button" ? "buttonEnter" : "swipeEnter"
            }
            animate={
              interactionType === "button" ? "buttonCenter" : "swipeCenter"
            }
            exit={interactionType === "button" ? "buttonExit" : "swipeExit"}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                if (isLastQuestion) {
                  handleSwipe(0);
                } else {
                  handleSwipe(1);
                }
              } else if (swipe > swipeConfidenceThreshold) {
                if (currentStep === 0) {
                  handleSwipe(0);
                } else {
                  handleSwipe(-1);
                }
              }
            }}
          >
            <Container maxWidth="sm" className="question-container">
              <div className="progress-container">
                <div className="prev-icon">
                  <Tooltip title="Previous">
                    <div
                      className={`icon-container ${
                        currentStep === 0 && "disabled"
                      }`}
                      onClick={
                        currentStep !== 0 ? () => handleSwipe(-1) : undefined
                      }
                    >
                      <ArrowBackIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className="progress-section">
                  <Grid container className="progresbar-wrapper">
                    <Grid item xs={9} md={10} className="progress-line">
                      <BorderLinearProgress
                        variant="determinate"
                        value={progressValue}
                      />
                    </Grid>
                    <Grid item xs={3} md={2} className="p-0 question-count">
                      <p className="my-0">
                        {currentStep + 1}/{data.length}
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className="next-icon">
                  <Tooltip title="Next">
                    <div
                      className={`icon-container float-right ${
                        isLastQuestion && "disabled"
                      }`}
                      onClick={
                        !isLastQuestion ? () => handleSwipe(1) : undefined
                      }
                    >
                      <ArrowForwardIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div>
                <div>
                  <Card className="question-card">
                    <p className="question-number">
                      Question No: {currentStep + 1}
                    </p>
                    {/* <p className="my-0" >{currentQuestion?.question}</p> */}
                    <p className="my-0" dangerouslySetInnerHTML={{ __html: htmlString }}></p>
                  </Card>
                  {currentQuestion?.image != null && 
                  <div className="img-card">
                    <img src={`${imageURL}${currentQuestion.image_path}${currentQuestion.image}`}/>
                  </div>
                  }
                  <div className="answer-options mx-auto">
                    <ol
                      type="A"
                      className={`${answerSelected && "selected-options"}`}
                    >
                      {currentQuestion?.answers.map((option, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              handleOptionClick(currentQuestion.id, option)
                            }
                            className={
                              selectedAnswer === option.id
                                ? "selected-answer"
                                : ""
                            }
                          >
                            {option.answer}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                </div>
              </div>
            </Container>
            <Container maxWidth="sm" className="p-0 w-100  footer1">
              {isLastQuestion ? (
                <div>
                  <Button
                    variant="contained"
                    className="btn-primary w-100 button"
                    disabled={isDisabled}
                    onClick={handleFinish}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  className="btn-primary w-100 button"
                  onClick={() => handleSwipe(1)}
                  disabled={selectedAnswer === null}
                >
                  Next
                </Button>
              )}
            </Container>
          </motion.div>
        </AnimatePresence>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleClose}
          severity={options.severity}
          sx={{ width: "100%" }}
        >
          {options.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default QuizQuestion;
