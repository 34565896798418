import React, { useContext, useEffect, useState } from "react";
import loksewaLogo from "../../assets/images/logo3.png";
import logo1 from "../../assets/images/logo2.png";

import axios from "axios";
import { baseURL } from "../../config/baseURL";

import { NumberContext } from "../../pages/Login";
import { otpSchema } from "../../schema/otpSchema";
import { useFormik } from "formik";

import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
  const mappedValue = (props.value / 120) * 100;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={mappedValue}  size={80}  className="circular-progress"/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)} sec`}
        </Typography>
      </Box>
    </Box>
  );
}

const OtpCode = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const value = useContext(NumberContext);
  const [remainingTime, setRemainingTime] = useState(120); // Initial countdown time in seconds
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        otp: "",
      },
      validationSchema: otpSchema,
    });

  useEffect(() => {
    let timer;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    // Clear the interval when the countdown reaches 0
    if (remainingTime === 0) {
      clearInterval(timer);
    }

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  // verify otp number
  const handleVerify = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("phone", value.values.phone);
    formData.append("otp", values.otp);
    handleSubmit();
    if (values.otp !== "" && errors.otp === undefined) {
      value.setLoading(true);
      console.log("entered inside")
      try {
        const response = await axios.post(
          `${baseURL}/daily-quiz/otp-verify-without-password`,
          formData
        );
        if (response.status === 200) {
          value.setOpen(true);
          value.setOptions({
            message: response.data.message,
            severity: "success",
          });
          localStorage.setItem(
            "access_token",
            response.data.data.access_token
          );
          value.setPasswordSignature(
            response.data.data.password_update_signature
          );
          value.setAddPassword(true);
          setIsSubmitting(true);
          value.setLoading(false);
        }
        setIsSubmitting(false);
      } catch (err) {
        value.setLoading(false);
        console.log(err.response);
        if (err.response.status === 401) {
          value.setOpen(true);
          value.setOptions({
            message: err.response.data.message,
            severity: "error",
          });
          setIsSubmitting(true);
          value.setLoading(false);
        }
        setIsSubmitting(false);
        value.setOpen(true);
        value.setOptions({
          message: err.response.data.message,
          severity: "error",
        });
      }
    }
  };

  //resend otp to a number
  const handleResendCode = async (e) => {
    e.preventDefault();
    value.setLoading(true);
    const formData = new FormData();
    formData.append("phone", value.values.phone);
    handleSubmit();
    if (value.values.phone !== "") {
      try {
        const response = await axios.post(
          `${baseURL}/daily-quiz/otp-send`,
          formData
        );
        value.setOptions({
          message: response.data.message,
          severity: "success",
        });
        value.setOpen(true);
        value.setLoading(false);
      } catch (err) {
        console.log(err.response);
        value.setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="login-form">
        <Container maxWidth="sm" className="login-inner-container">
          <img src={logo1} alt="logo" className="logo-img image" />
          <p className="text">
            Please enter 6 digit OTP code that we sent to your number.
          </p>
          <div className="login-form">
            <div>
              <TextField
                id="outlined-start-adornment"
                className={
                  errors.otp && touched.otp
                    ? "input-error form-field"
                    : "form-field"
                }
                type="text"
                name="otp"
                sx={{ m: 1 }}
                placeholder="Enter your otp code"
                value={values.otp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.otp && touched.otp && (
                <div className="error-message" style={{ color: "red" }}>
                  {errors.otp}
                </div>
              )}
            </div>
            {remainingTime !== 0 && (
              <CircularProgressWithLabel value={remainingTime} />
            )}
          </div>
        </Container>
        <Container maxWidth="sm" className="p-0 w-100  footer">
          <Button
            variant="contained"
            className="btn-primary button"
            type="submit"
            disabled={isSubmitting}
            onClick={handleVerify}
          >
            Verify
          </Button>

          <p>
            Didn't get a code?{" "}
            <span
              onClick={remainingTime === 0 ? handleResendCode : undefined}
              className={`${remainingTime !== 0 && "code-disabled"}`}
            >
              Resend code
            </span>
          </p>
        </Container>
        {/* <Button variant="contained" className="btn-primary button" type='submit'>
                Send
            </Button> */}
      </div>
    </div>
  );
};

export default OtpCode;
