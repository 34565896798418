import { Button, Card, Container, Divider } from "@mui/material";
import React from "react";
import cup from "../assets/images/celebrate.png";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { green, red } from "@mui/material/colors";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const ScorePage = () => {
  let correct_answer = sessionStorage.getItem("correct_answer");
  let total_questions = sessionStorage.getItem("total_questions");
  let incorrect_answer = total_questions - correct_answer;
  let score = sessionStorage.getItem("score");
  let pass = score >= 0.5;
  return (
    <div>
        <Container maxWidth="sm" className="outer-container">
            <div className="welcome-header">
            <div className="result-background-image">
                <h1>Your score</h1>
            </div>
            </div>
        </Container>
        <div className="result-circle"></div>
      <Container maxWidth="sm">
        <div className="score-img-container">
          <img src={cup} className="score-img" alt="successImg" />
        </div>
        <Card className="result">
          <Grid container spacing={4} className="result-content">
            <Grid item xs={12} sm={6} order={{xs:2, sm:1}} className="score-details">
              {pass ? (
                <h2 className="my-0 mb-1">Congratulations!!</h2>
              ) : (
                <h2 className="my-0 mb-1">Try Again!!!</h2>
              )}
              <p className="my-0 mb-1">Here are your quiz results.</p>
              <p className="my-0 mb-1">
                If you didn't pass the quiz, please review your incorrect
                choices and retake the quiz when you are ready
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              order={{xs:1, sm:2}}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {pass ? (
                <Avatar sx={{ bgcolor: green[500], width: {xs:100, sm:160}, height: {xs:100, sm:160} }}>
                  <ThumbUpIcon sx={{ width: {xs:50, sm:100}, height: {xs:75, sm:100} }} />
                </Avatar>
              ) : (
                <Avatar sx={{ bgcolor: red[500], width: {xs:100, sm:160}, height: {xs:100, sm:160} }}>
                  <ThumbDownIcon sx={{ width: {xs:75, sm:100}, height: {xs:75, sm:100} }} />
                </Avatar>
              )}
            </Grid>
          </Grid>
          <hr className="line" />
          <Grid container spacing={2} className="result-score">
            <Grid item xs={6} md={3} className="result-item">
              <h2 className="my-0">
                {correct_answer}
                <span>/{total_questions}</span>
              </h2>
              <p className="my-0 text-success">Correct Answers</p>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: { xs: "100px", md: 0 }, display:{xs:"block", md:"none"}  }} />
            <Grid item xs={5} md={3} className="result-item">
              <h2 className="my-0">
                {incorrect_answer}
                <span>/{total_questions}</span>
              </h2>
              <p className="my-0 text-danger">Incorrect Answers</p>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: { xs: "100%", md: 0 }, display: { xs: "block", md: "none" } }} />
            <Divider sx={{ width: { xs: "100%", md: 0 } }} />
            <Grid item xs={6} md={3} className="result-item incorrect-answer">
              <h2 className="my-0">{score * 100}%</h2>
              <p className="my-0 text-success">Your Score</p>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: { xs: "100px", md: 0 }, display:{xs:"block", md:"none"}  }} />
            <Grid item xs={5} md={3} className="result-item">
              <h2 className="my-0 text-gray">50%</h2>
              <p className="my-0 text-gray">Passing Score</p>
            </Grid>

          </Grid>
        </Card>
        <Link to="/result">
          <Button variant="contained" className="btn-secondary button">
            See Answers
          </Button>{" "}
        </Link>
      </Container>
    </div>
  );
};

export default ScorePage;
