import React, { useState } from "react";
import logo from "../assets/images/logo1.png";
import { Alert, Container } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useFormik } from "formik";
import Loader from "../components/Loader/Loader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import loksewaLogo from "../assets/images/logo3.png";
import logo1 from "../assets/images/logo2.png";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { baseURL } from "../config/baseURL";
import axios from "axios";
import { passwordSchema } from "../schema/passwordSchema";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  //snackbar
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    message: "",
    severity: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  
  //formik
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        //for phone number
        otp: "",
        password: "",
        confirmPassword: "",
      },
      validationSchema: passwordSchema,
    });
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
  setShowConfirmPassword((show) => !show);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const phone = localStorage.getItem("phone");
    const formData = new FormData();
    formData.append("otp", values.otp);
    formData.append("phone", phone);
    formData.append("password", values.password);
    formData.append("confirm_password", values.confirmPassword);
    handleSubmit();
    if (
      values.otp &&
      values.password !== "" &&
      errors.otp === undefined &&
      errors.password === undefined
    ) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/password/reset`,
          formData
        );
        console.log(response);
        if (response?.status === 200) {
          localStorage.setItem(
            "access_token",
            response?.data.data.access_token
          );
          sessionStorage.setItem('tokenApproved', true);
          navigate("/question");
          setOpen(true);
          setOptions({
            message: response?.data.message,
            severity: "success",
          });
          setIsSubmitting(false);
          setLoading(false);
        }
        setIsSubmitting(false);
      } catch (err) {
        console.log(err);
        setOpen(true);
        setOptions({
          message: err.response.data.message,
          severity: "error",
        });
        setIsSubmitting(false);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Container maxWidth="sm" className="outer-container">
            <div className="login-header">
              <div className="background-image-login">
                <img src={logo} alt="logo" className="image" />
              </div>
            </div>
          </Container>
          <div className="circle"></div>
          <form>
            <div>
              <Container maxWidth="sm" className="login-inner-container">
                <img src={logo1} alt="logo" className="logo-img image" />
                <p className="my-0 text">
                  Please enter your otp code that you get in your phone and
                  assign a new password
                </p>
                <div className="password-form">
                  <div>
                    <TextField
                      id="outlined-start-adornment"
                      className={
                        errors.otp && touched.otp
                          ? "input-error form-field"
                          : "form-field"
                      }
                      type="text"
                      name="otp"
                      sx={{ m: 1 }}
                      placeholder="Otp code"
                      value={values.otp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.otp && touched.otp && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.otp}
                      </div>
                    )}
                  </div>
                  <div>
                    <FormControl
                      sx={{ m: 1 }}
                      variant="outlined"
                      className={
                        errors.password && touched.password
                          ? "input-error form-field"
                          : "form-field"
                      }
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="New Password"
                      />
                    </FormControl>
                    {errors.password && touched.password && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div>
                    <FormControl
                      sx={{ m: 1 }}
                      variant="outlined"
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "input-error form-field"
                          : "form-field"
                      }
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Confirm Password"
                      />
                    </FormControl>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                </div>
              </Container>
              <Container maxWidth="sm" className="p-0 w-100  footer1">
                <Button
                  variant="contained"
                  className="btn-primary button"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleUpdatePassword}
                >
                  Save
                </Button>
              </Container>
            </div>
          </form>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        {options.severity ? (
          <Alert
            onClose={handleClose}
            severity={options.severity}
            sx={{ width: "100%" }}
          >
            {options.message}
          </Alert>
        ) : null}
      </Snackbar>
    </div>
  );
};

export default ForgotPassword;
