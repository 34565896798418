import React from 'react'
import { Audio } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div className='loader-container'>
            <Audio
                height="100"
                width="100"
                color="#FFA000"
                ariaLabel="audio-loading"
                wrapperStyle={{}}
                wrapperClass="wrapper-class"
                visible={true}
            />
        </div>
    )
}

export default Loader