import * as yup from "yup";

export const passwordSchema = yup.object().shape({
    otp: yup
    .number()
    .min(6, "Otp must be at least of 6 letters")
    .typeError("OTP must be a number")
    .required("This field is required"),
    email: yup.string().email("Field should contain a valid e-mail").max(255),
    password: yup.string()
    .matches(/^\S*$/, "Password must not contain any space")
    // .matches(/[a-z]+/, "Password must have one lowercase character")
    // .matches(/[A-Z]+/, "Password must have one uppercase character")
    // .matches(/[@$!%*#?&]+/, "Password must have one special character")
    // .matches(/\d+/, "Password must have one number")
    // .min(8, "Password must be 8 characters or more")
    // .max(16, "Password must be 16 characters or less")
    .required("This field is required"),
    confirmPassword: yup.string().oneOf(
        [yup.ref("password"), null],
        "Password and Confirm Password must match"
    ).required("This field is required"),
})  
